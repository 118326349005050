
  import Vue from "vue";
  import PwaSupport from "@/components/PwaSupport.vue";
  import pageMixin from "@/mixins/page";

  export default Vue.extend({
    name: "Support",
    mixins: [pageMixin],
    components: {PwaSupport}
  });
