import { isValidEmail } from "@/utils/common";
import { app } from '@/main';

export type ImageType = { image: string, name: string, type: string, size: number };

export const getInputErrorMessage = (input: HTMLInputElement, value: string, inputLabel: string = null): string => {
  let errorMessage = '';
  if (input.required && !value) {
    const field = inputLabel || input.placeholder.toLowerCase();
    errorMessage = app.$t('58', {field});
  } else if (input.type === 'email' && !isValidEmail(value)) {
    const field = inputLabel || input.placeholder.toLowerCase();
    errorMessage = app.$t('59', {field});
  } else if (input.minLength && value.length < input.minLength) {
    errorMessage = app.$t('74', {length: input.minLength});
  }

  return errorMessage;
};

export const hasErrors = (errors: Record<string, string>): boolean => {
  let hasErrors = false;
  for (const field in errors) {
    if (errors[field]) {
      hasErrors = true;
      break;
    }
  }

  return hasErrors;
};

export const hasRequiredNotFilled = (requiredList: Array<string>, values: Record<string, string>): boolean => {
  const notFilledItem = requiredList.find(requiredItem => !values[requiredItem]);

  return Boolean(notFilledItem);
};

export const IMAGES_TYPES_ACCEPTED = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/gif'
];

export const isValidImage = (type: string): boolean => IMAGES_TYPES_ACCEPTED.indexOf(type) >= 0;

export const IMAGES_READ_ERRORS = {
  TYPE_NOT_ALLOWED: 'TYPE_NOT_ALLOWED',
  SIZE_NOT_ALLOWED: 'SIZE_NOT_ALLOWED',
  UNKNOWN: 'UNKNOWN',
};

export const IMAGE_MAX_SIZE = 20; // Mb

export const getImage = (image: File): Promise<ImageType> => {
  return new Promise((resolve, reject) => {
    const size: number = image.size / 1024 / 1024;
    const type = image.type;
    const name = image.name;

    if (size > IMAGE_MAX_SIZE) {
      reject(IMAGES_READ_ERRORS.SIZE_NOT_ALLOWED);
    } else if (!isValidImage(type)) {
      reject(IMAGES_READ_ERRORS.TYPE_NOT_ALLOWED);
    } else {
      const reader = new FileReader();
      reader.onloadend = (evt => {
        const readImage = evt.target.result.toString();
        resolve({image: readImage, name, type, size});
      });

      reader.onerror = (() => {
        reject(IMAGES_READ_ERRORS.UNKNOWN);
      });

      reader.readAsDataURL(image);
    }
  });
};
