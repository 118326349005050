
  import Vue from "vue";
  import {getInputErrorMessage, hasErrors, hasRequiredNotFilled} from "@/utils/form";
  import PwaIcon from "@/components/common/PwaIcon.vue";
  import {MODAL_IDS} from "@/utils/modal";
  import {ROUTES} from "@/router/routes";
  import { EVENTS_NAME } from '@/services/analytics';
  import {showInterstitial} from "@/services/ads";

  const requiredItems = ['name', 'email', 'message'];
  export default Vue.extend({
    name: "pwa-support",
    components: {PwaIcon},
    data() {
      return {
        routes: ROUTES,
        acceptPrivacyPolice: false,
        form: {
          name: '',
          email: '',
          phone: '',
          message: '',
        } as any,
        errors: {}
      }
    },
    computed: {
      hasErrors(): boolean {
        return hasErrors(this.errors);
      },
      hasRequiredNotFilled(): boolean {
        return hasRequiredNotFilled(requiredItems, this.form);
      },
      submitDisabled(): boolean {
        return this.hasRequiredNotFilled || this.hasErrors;
      },
    },
    mounted() {
      if (this.userId) {
        this.form.name = this.$store.state.auth.user.name;
        this.form.email = this.$store.state.auth.user.email;
      }
    },
    methods: {
      async submit() {
        try {
          if(!this.acceptPrivacyPolice){
            this.showToastError(this.$t('139'), this.$t('324'));
          }else{
            this.showLoader(true);
            let data = {
              name: this.form.name,
              email: this.form.email,
              message: this.form.message
            } as any;
            if (this.form.phone) {
              data.phone = this.form.phone;
            }
            await this.$http.user.postSupport(data);
            this.showToastSuccess(this.$t('238'), this.$t('239'));
            this.sendAnalyticsEvent(EVENTS_NAME.CONTACT);
            showInterstitial();
            this.onCancelClick();
          }
        } catch (error) {
          this.showToastError(this.$t('139'), this.$t('140'));
        } finally {
          this.showLoader(false);
        }
      },
      onInputBlur(event: Event) {
        const input = event.target as HTMLInputElement;
        const field = input.name;
        const errorMessage = this.getInputErrorMessage(event);

        this.$set(this.errors, field, errorMessage);
      },
      getInputErrorMessage(event: Event) {
        const input = event.target as HTMLInputElement;
        const field = input.name;
        const value: string = this.form[field];
        return getInputErrorMessage(input, value);
      },
      onCancelClick() {
        if (this.isDesktop) {
          this.hideModalById(MODAL_IDS.SUPPORT);
        } else {
          this.$router.back();
        }
      }
    }

  });
